import { Contact } from '../common'

export enum BookingPhotographerStatus {
  ASSIGNED = 'Assigned',
  COMPLETE = 'Complete',

  // These two are defined in booking/salessupport
  NOTIFIED = 'notified',
  CONFIRMED = 'confirmed'
}

export interface BookingPhotographer {
  contact?: Partial<Contact>
  id: string
  name: string
  photographerId: string
  status: BookingPhotographerStatus
  isRigPhotographer?: boolean
  isSharedPhotographer?: boolean
}

export interface BookingPhotographerMap {
  [id: string]: BookingPhotographer
}
