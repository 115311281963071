import { ImageEntry } from '../images'
import TimeStamp from '../timestamp'

export enum DownloadStatus {
  PREPARING = 'Preparing',
  REQUESTED = 'Requested',
  ERRORED = 'Errored',
  FINISHED = 'Finished'
}

export enum ImageFileType {
  JPG = 'jpg',
  BMP = 'bmp'
}

export interface ImagePrepSettings {
  x?: number
  y?: number
  adjust?: boolean
  nexuscrop?: boolean
  sharpen?: boolean
  method?: number
  exifrotate?: boolean
  needscc?: boolean
  watermark?: boolean
  watermarkopacity?: number
  watermarkcolour?: string
  keeptimestamp?: boolean
  filetype?: ImageFileType
  quality?: number
  xinch?: number
  yinch?: number
}

export interface ImagePrepPreset{
  id?: string
  name: string
  settings: ImagePrepSettings
}

export interface LongRefFileNamePair {
  longRef: string
  fileName: string
  className: string
  nameKeyReassigned: boolean
}

export interface DownloadManifest {
  contextId: string | null
  downloadRequestId: string
  longRefPairs: LongRefFileNamePair[]
}

type PickKeys<T, U extends keyof T> = {
  [K in keyof T]: K extends U ? K : never
}[keyof T]

export type DownloadRequestFileNameKey = PickKeys<ImageEntry, 'ref' | 'pupilId' | 'longRef'>

export interface DownloadRequest {
  id: string
  contextId: string | null
  addEditedFolder: boolean
  classFolders: boolean
  status: DownloadStatus
  longRefs: string[]
  prepSettings: Partial<ImagePrepSettings> | null
  imageCount: number
  imageFinishedCount: number
  zipDownloadName: string
  nameKey: keyof ImageEntry
  startTime: TimeStamp
  endTime: TimeStamp | null
  userId: string
}

export interface DownloadSettings {
  userId: string
  longRefs: string[]
  zipDownloadName: string
  prepSettings: ImagePrepSettings | null
  classFolders: boolean
  addEditedFolder: boolean
  contextId: string
  keyName: string
}

export function getDefaultPrepSettings (): ImagePrepSettings {
  return {
    x: 0,
    y: 0,
    adjust: true,
    nexuscrop: true,
    sharpen: false,
    needscc: true,
    watermark: false,
    keeptimestamp: true,
    xinch: 0,
    yinch: 0,
    watermarkopacity: 0.6,
    watermarkcolour: '#ffffff',
    filetype: ImageFileType.JPG,
    quality: 85
  }
}
