export enum Currency {
  GBP = 'GBP',
  EURO = 'EURO'
}

// export enum NexusJobSubType {
//   earlyYearsGradsPVS = 1094,
//   earlyYearsPVS = 1093,
//   earlyYearsPE = 1108,
//   earlyYearsSomeOneYouLovePE = 1117,
//   schoolsClassGroupLarge = 1055,
//   schoolsCompositeCombi = 1138,
//   schoolsGroup10x5PE = 1037,
//   schoolsGroup10x5WithSchoolNamePE = 1039,
//   schoolsGroup10x7PE = 1116,
//   schoolsGroup10x7WithSchoolNamePE = 1040,
//   schoolsGroup10x10PE = 951,
//   schoolsGroup18x8PE,
//   schoolsGroupClassTeam = 1074,
//   schoolsGroupSnappersWhite = 1135,
//   schoolsInTheFrame = 1068,
//   schoolsLargeGroupPortraitCombiPE = 1102,
//   schoolsLargeGroupPortraitCombiPVS = 1103,
//   schoolsLargeGroupPortraitCombiWithSchoolNamePE = 1100,
//   schoolsPortraitPE = 1090,
//   schoolsPortraitPVS = 1105,
//   schoolsPortraitWithSchoolNamePVS = 1098,
//   schoolsPortraitWithSchoolNamePE = 1115,
//   schoolsPremierGroup = 1083,
//   schoolsPrimaryLeaversPE = 1119,
//   schoolsPrimaryLeaversPVS = 1118,
//   schoolsSmallGroupCombiPE = 1050,
//   schoolsSmallGroupCombiWSNPE = 1052,
//   schoolsSmallGroupCombiWINPE = 1053,
//   schoolsVista = 1086,
//   schoolsVista2 = 1087,
//   schoolsVista2Combi = 1088,
//   schoolsVistaPod = 1133,
//   schoolsWholeSchoolGroup = 1054,
//   unknown = -1
// }

export interface Commission {
  modOrders: number
  webOrders: number
}

export interface OrderMethods {
  viaSchool: boolean
  direct: boolean
  web: boolean
}

export enum OrderFormPrinting {
  onTheDayPVS = 'PVS',
  labPE = 'PE',
  none = 'None'
}

export enum FormPrint {
  ON_THE_DAY = 'OnTheDay',
  LAB = 'Lab',
  CONTACTLESS = 'Contactless',
  NONE = 'None'
}

export interface AimsCode {
  isActive?: boolean
  commissionToAccount: Commission
  id: string
  ordersDataCode: string
  formPrint: FormPrint
}

export interface ProductLine {
  aimsCodes: AimsCode[]
  backToSchoolDelivery: boolean
  catalog: string
  currency: Currency
  marketId: string
  name: string
  orderMethods: OrderMethods
  shootToCloudEnabled: boolean
  withIndividualNames: boolean
  withSchoolName: boolean

  accessCodes: AccessCodeMap[]
}

export interface AccessCodeMap {
  accessCode: string
  takeDateFromUnixTimestamp: number
  takeDateToUnixTimestamp: number
  validUntilUnixTimestamp: number
  priceBand: string
  homeDeliveryCost: number
}
