import { GeoCoordinates } from '../common'
import { Relationship, RelationshipType } from '../relationships'

export enum OrganisationType {
  ROOT = 'root',
  MARKET = 'market',
  AREA = 'area',
  TERRITORY = 'territory'
}

export interface Identity {
  id: string
  code: string
  name: string
}

export interface Organisation extends Identity {
  type: OrganisationType
  accountBoundaries?: GeoCoordinates[]
}

export interface OrganisationNode {
  id: string
  organisations?: Relationship[]
  userProfiles?: Relationship[]
  accounts?: Relationship[]
}

export interface OrganisationUser extends Identity {
  type: RelationshipType
}
