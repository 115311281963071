import { EditJob, ImageEditingActiveStatuses } from '@ht-lib/accounts-models'
import { uniqWith } from 'lodash'
import { app } from '../firebase'

const editColl = app.firestore().collection('imageEditJobs').withConverter<EditJob>({
  fromFirestore: (d) => d.data() as EditJob,
  toFirestore: (d) => d
})

export function activeEditJobsQuery () {
  return editColl
    .where('status', 'in', ImageEditingActiveStatuses)
}

async function getValuesWhichStartWith (term: string, key: keyof EditJob) {
  return await editColl.where(key, '>=', term).where(key, '<=', term + '\uf8ff').limit(10).get()
}

export async function searchAll (term: string) {
  console.log(term)
  const snaps = await Promise.all([
    getValuesWhichStartWith(term, 'title'),
    getValuesWhichStartWith(term.toUpperCase(), 'data'),
    editColl.where('data', 'array-contains', term.toUpperCase()).limit(10).get()
  ])

  console.log(snaps)

  const editJobs = snaps.reduce<EditJob[]>((jobs, snap) => {
    return jobs.concat(snap.docs.map(x => x.data()))
  }, [])

  return uniqWith(editJobs, (a, b) => a.id === b.id)
}

export async function addEditJob (job: EditJob) {
  return await editColl.doc(job.id).set(job)
}

export function editJobDoc (id: string) {
  return editColl.doc(id)
}

export async function updateEditJob (id: string, job: Partial<EditJob>) {
  return await editColl.doc(id).set(job, { merge: true })
}
