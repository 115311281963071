import { Market } from '../common/market'

export enum ImageStatus {
  FLAGGED = 'FLAGGED',
  REJECTED = 'REJECTED',
  NONE = 'NONE'
}

export interface VaultArchiveMigration {
  isImageCorrupt?: boolean
  isRestoredFromArchive?: boolean
  restoredFromArchivePath?: string
}

type VaultArchiveMigrationMeta = Partial<Record<keyof VaultArchiveMigration, string>>

// metaString is the stringified version of the actual image meta.
// Storage Objects metadata doesn't support nested fields so we strinigy
export interface VaultMeta extends
  Omit<VaultArchiveMigrationMeta, 'isImageCorrupt'> {
  [key: string]: string | undefined
  metaString?: string

  // nexusId is set when the image has been uploaded by image uploader
  nexusId?: string

  // market is set when the image has been uploaded by image uploader
  market?: Market
}

export interface ColourCorrectionDetails {
  modelId: string
  value: number
  r: number | null
  g: number | null
  b: number | null
  author: string | null
}

export interface Nudge {
  left: number
  top: number
}

export interface CCPredictedMessage {
  longRef: string
  details: ColourCorrectionDetails
}

export interface CCAdjustedMessage {
  longRef: string
  details: Partial<ColourCorrectionDetails>
  nudge?: Nudge
}

export interface AccountLink {
  accountId: string
  pupilId: string
}

export interface NewImagesMessage {
  email: string | null
  mobile: string | null
  pupilContactId: string[]
  longRefs: string[]
  removedRefs?: string[]
  sendEmailNow?: boolean
  sendMobileNow?: boolean
  accountLinks: AccountLink[]
}
export enum ShotType {
  PortraitMode = 1,
  IndoorGroup = 2,
  OutdoorGroup = 3 // Only used in Studio ¯\_(ツ)_/¯
}

export interface ImageEntry extends VaultArchiveMigration {
  bookingId: string | null
  studioJobId: string | null
  accountId: string | null
  jobId: string | null
  pupilId: string | null
  uploaderId: string | null
  nexusId: number | null
  accountCode: string
  jobCode: string | null
  hash: string | null
  longRef: string
  pupilName: string | null
  market: Market
  ref: string
  status: ImageStatus
  className: string | null
  photographerCode: string
  orientation: number
  studioRejected: boolean
  studioFlagged: boolean
  uploaded: boolean
  childProtectionIssue: boolean
  isPreferred: boolean
  shotTypeId: ShotType
  takeDate: string
  imported: boolean
  jobType: string | null
  jobSubTypeId: number | null
  sizingCode: string | null
  ratio: string | null
  colourCorrection: ColourCorrectionDetails | null
  nudge: Nudge | null
  labUploader?: string // The person in the lab who uploaded it on behalf of the photographer

  /** URLs for generated thumbnails */
  thumbnailLowName?: string
  thumbnailHighName?: string
  /** END */
}

export interface NexusId {
  nexusId: number
  market: Market
}

export interface RefId {
  ref: string
  market: Market
}

export type NexusIdentity = RefId | NexusId

// eslint-disable-next-line
export function isNexusId (obj: any): obj is NexusId {
  return typeof obj?.nexusId === 'number' && typeof obj?.market === 'string'
}

// eslint-disable-next-line
export function isRefId (obj: any): obj is RefId {
  return typeof obj?.ref === 'string' && typeof obj?.market === 'string'
}

export const shotModeToShotType = (s: string): ShotType => {
  if (s === 'G') {
    return ShotType.IndoorGroup || ShotType.OutdoorGroup
  }
  return ShotType.PortraitMode
}
