import { Market } from '../common'
export * from './download'

export enum EditDataType {
  LONGREFS = 'Image Long Refs',
  REFS = 'Image Refs',
  AIMSJOB = 'Aims Job Number',
}

export enum EditJobStatus {
  HOLD = 'On Hold',
  CHECKING_IMAGES = 'Checking Images',
  UNASSIGNED = 'Unassigned',
  ASSGINED = 'Assigned',
  IN_PROGRESS = 'In Progress',
  ERROR = 'Errored',
  COMPLETE = 'Complete'
}

export enum ActionCategory {
  EXPOSURE = 'Exposure',
  DOWNLOAD = 'Download',
  COPYRIGHT_DWN = 'Copyright Download',
  HAZE = 'Haze',
  BACKGROUND = 'Background Repair',
  SNOTTY = 'Snotty Nose',
  REFLECTION = 'Reflection',
  HEAD_SWAPS = 'Head Swaps',
  EYE_SWAPS = 'Eye Swaps',
  BARCUTOFF = 'Cut Off',
  SHARPENING = 'Sharpening',
  FRAMING = 'Framing/Crop/Resize',
  CENTER = 'Center UP/DOWN',
  SHINE = 'Shine',
  FOOD = 'Food',
  FALL_OFF = 'Fall off',
  TRIP = 'Triptych',
  ROT = 'Rotation',
  WEBSITE ='Website',
  COMPOSITE = 'Composite',
  Bespoke = 'Bespoke Composite',
  REFRACTION = 'Refraction',
  RED_EYE = 'Red Eye',
  UNDERWARE = 'Underwear Showing',
  UNIFORM = 'Tidy Uniform',
  COSMETIC = 'Cosmetic',
  BESPOKE = 'Bespoke',
  ARTWORK_UPLOAD = 'Artwork Upload',
  SOME_ONE_LOVE = 'Someone You Love',
  ARTWORK_AMEND = 'Artwork Amendment',
  STUDIO_GROUP = 'Studio Group',
  CMOS = 'CMOS',
  MG = 'MG',
  OTHER = 'Other',
  NONE = 'None',
}

export enum EditJobPriority {
  URGENT = 'Urgent',
  PRIORITY = 'High Priority',
  NORMAL = 'Normal'
}

export enum EditJobSource {
  CUSTOMER_SERVICE = 'Customer Service',
  DIRECT_ORDERES = 'Direct Orders',
  ORDER_ENTRIES = 'Order Entry',
  SALES = 'Sales',
  SANDHURST = 'Sandhurst',
  MILITARIY = 'Tempest Military',
  PRODUCTION = 'Production',
  PIRBRIGHT = 'Pirbright',
  GRANDTHAM = 'Grantham',
  PACKING = 'Packing',
  PREMIER = 'PREMIER',
  PRINTING = 'Printing',
  QC = 'QC',
  AUTOMATIC = 'Automatic',
  OTHER = 'Other'
}

export interface EditJob {
  id: string
  createTime: number
  createdUser: string | null
  completeTime: number | null
  dataType: EditDataType
  market: Market
  jobSource: EditJobSource
  data: string[] | string
  title: string
  showOnlyFlagged: boolean
  price: number
  userInputTimeTaken: number | null
  assignedUserId: string | null
  editedBy: string[]
  status: EditJobStatus
  actionNotes: string
  headCount: number | null
  actionCategory: ActionCategory
  priority: EditJobPriority
  notes: string
  errorText: string
}

export function editPriorityValue (p: EditJobPriority | EditJob) {
  const priority = typeof p !== 'string' ? p.priority : p
  if (priority === EditJobPriority.URGENT) return 3
  if (priority === EditJobPriority.PRIORITY) return 2
  return 1
}

export const multiValueEditTypes: EditDataType[] = [
  EditDataType.LONGREFS,
  EditDataType.REFS
]

export function isTypeMultiValue (data: EditJob | EditDataType) {
  if (typeof data === 'string') {
    return !!multiValueEditTypes.find(a => a === data)
  }
  return !!multiValueEditTypes.find(a => a === data.dataType)
}
export const ImageEditingActiveStatuses: EditJobStatus[] = [EditJobStatus.UNASSIGNED, EditJobStatus.HOLD, EditJobStatus.CHECKING_IMAGES, EditJobStatus.IN_PROGRESS, EditJobStatus.ASSGINED, EditJobStatus.ERROR]
