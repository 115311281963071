import { PaymentStatus } from '../transactions'
import { ContactDetail } from './contactDetail'

export enum ContactStatus {
  DELIVERY_FAILED = 'DELIVERY_FAILED',
  MESSAGE_SENT = 'MESSAGE_SENT'
}

export interface ContactLog {
  addresss: string
  unixDateTime: number
  event: string
}

export interface PupilContact {
  log?: ContactLog[]
  pupilContactId?: string
}

export interface SchoolContact extends PupilContact {
  parentEmail: string | null
  parentEmailStatus: ContactStatus | null
  parentMobile: string | null
  parentMobileStatus: ContactStatus | null
}

export interface GradContact extends PupilContact {
  email: string
  emailStatus?: ContactStatus
  mobile: string | null
  mobileStatus?: ContactStatus
}

interface PupilBase {
  longRefs?: string[]
  name: string
  preferredImage?: string
  staff: boolean
}

export interface SchoolPupil extends PupilBase {
  class: string
  contacts: SchoolContact[]
  id: string
  schoolYear: string
}

export interface GradPupil extends PupilBase {
  bookingSlotId?: string
  docketId: string
  depositDiscountReclaimed?: PaymentStatus
  contacts: GradContact[]
  faculty?: string
  gownSize: string
  headSize: string
  paymentAmount?: number
  paymentDate?: number
  paymentReference?: string
  paymentStatus?: PaymentStatus
  paymentNotes?: string
  programme?: string
  qualification?: string
  studentId: string
  updatedUnixTimestamp?: number
}

export type PupilDocument = SchoolPupil | GradPupil

export type PupilDocumentContact = SchoolContact | GradContact

export interface UnlinkPupilFromContactMessage {
  accountId: string
  pupilId: string
  contact: Required<ContactDetail>
  longRefs: string[]
}

export function isGradPupil (val: PupilDocument): val is GradPupil {
  return typeof (val as GradPupil).studentId !== 'undefined'
}

export function isGradContact (val: PupilDocumentContact): val is GradContact {
  return typeof (val as GradContact).email !== 'undefined'
}
