import { Contact, Note, Venue } from '..'
import { Market } from '../common'
import Timestamp from '../timestamp'

export enum AccountStatus {
  ARCHIVED = 'archived',
  CUSTOMER = 'customer',
  GOLD = 'gold',
  MOST_WANTED = 'most_wanted',
  NON_CUSTOMER = 'non_customer',
  NON_VIABLE = 'non_viable',
  PLATINUM = 'platinum',
  SILVER = 'silver'
}

export enum AccountActivityAction {
  CALL = 'call',
  EMAIL = 'email',
  MAILSHOT = 'mailshot',
  CANVASSING = 'canvassing',
  GAIN = 'gain',
  LOSS = 'lost',

  // Deprecated
  OFSTED_MUG_FOLLOWUP = 'OFSTED',

  // Deprecated
  OFSTED_MUG_SENT = 'OFSTEDMug',
  OTHER = 'other',
  VISIT = 'visit'
}

export interface Activity {
  action: AccountActivityAction
  note: string
  timestamp: number
  createdUser: string
  createdUserId: string
  messageId?: string
  updatedUser?: string
  updatedUserId?: string
}

export interface SmartpayConfig {
  liveHmac: string
  skinCode: string
  testHmac: string
  useLivePayment: boolean
}

export interface Account {
  accountHolderContactInfo: Contact
  activities?: Activity[]
  aimsBusinessNo?: number
  archiveReason?: string
  archiveNotes?: string
  areaCode?: string
  authorityCode?: string
  code?: string
  competitorName?: string
  contacts?: Contact[]
  hasWarning: boolean
  headCount?: string
  indSalesRep?: string
  isArchived: boolean
  isCustomer: boolean
  lastJobUploadedUnixTimestamp?: number
  lastUpdatedTimestamp: Timestamp
  lastUpdatedUser: string
  lastUpdatedUserId: string
  mainAddress: string
  mainContact?: string
  market: Market | null
  marketCode?: string
  marketTitle?: string
  misSystem?: string
  misSystemUpdated?: Timestamp
  name: string
  nextBookingDate?: string
  nextBookingTime?: string
  nextBookingTimestamp?: number
  notes: Note[]
  options?: { noNotificationOnCreated?: boolean }
  pupilsCleanedUnixTimestamp?: number | null
  pupilsImportedUnixTimestamp: number | null
  pupilsHaveContacts?: boolean
  pupilImportConsent: boolean | null
  relationshipDetail?: string
  /** userProfile IDs that have access */
  usersWithPhotographerPermissions?: string[]
  /** Account Holders (Manager) and Assistants */
  usersWithManagerPermissions?: string[]
  smartpay?: SmartpayConfig
  status: AccountStatus
  unarchiveReason?: string
  venues: Venue[]
  warningText?: string
  hasBookingChangeRequests?: boolean
  dummy?: Timestamp | string // hack to allow forcing elastic search to update
}
