export enum ActivityType {
  ACCOUNT = 'Account',
  BOOKING = 'Booking',
  AUTH = 'Auth',
  CONTACT = 'Contact',
  DISCOUNTADDED = 'DiscountAdded',
  DISCOUNTCLAIMED = 'DiscountClaimed',
  DISCOUNTCLAIMREFUNDED = 'DiscountClaimRefunded',
  DISCOUNTEXISTS = 'DiscountExists',
  DISCOUNTINVALIDATED = 'DiscountInvalidated',
  IMAGE = 'Image',
  JOB = 'Job',
  PUPIL = 'Pupil',
  PORTALACTION = 'PortalAction',
  GRADACTION = 'GradAction',
  CUSTOMERPROFILE = 'CustomerProfile',
  SETTINGSUPDATE = 'SettingsUpdate',
  RELATIONSHIP = 'Relationship'
}

export interface ActivityEntry {
  contextId: string
  userId: string | null
  message: string
  type: ActivityType
  timestamp: number
  labels: string[]
}
