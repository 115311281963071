import { OrganisationType } from '../organisation'

export enum RelationshipObject {
  ACCOUNT = 'account',
  BOOKING = 'booking',
  CONTACT = 'contact',
  USERPROFILE = 'userProfile',
  ORGANISATION = 'organisation'
}

export enum RelationshipType {
  ASSISTS = 'assists',
  ASSISTS_TERRITORY = 'assistsTerritory',
  CAN_SEE_ACCOUNT = 'canSeeAccount',
  HAS_PARENT = 'hasParent',
  IS_IN_AREA = 'isInArea',
  IS_IN_MARKET = 'isInMarket',
  IS_IN_ROOT = 'isInRoot',
  IS_IN_TERRITORY = 'isInTerritory',
  IS_PHOTOGRAPHING_AT = 'isPhotographingAt',
  IS_WITH_ACCOUNT = 'isWithAccount',
  MANAGES = 'manages',
  MANAGES_AREA = 'managesArea',
  MANAGES_TERRITORY = 'managesTerritory',
  PORTAL_ACCESS_TO = 'portalAccessTo',
  USED_PHOTOGRAPHER = 'usedPhotographer'
}

export interface RelationshipFrom {
  objectFromCode: string
  objectFromName: string
  objectFromType: RelationshipObject | OrganisationType
  objectFromId: string
}

export interface RelationshipTo {
  objectToCode: string
  objectToName: string
  objectToType: RelationshipObject | OrganisationType
  objectToId: string
}

export interface Relationship extends RelationshipFrom, RelationshipTo {
  type: RelationshipType
  ignoreChange?: boolean
}
