export interface EmailMessage {
  messageId?: string
  provider?: 'AWS'
  to: string[]
  from: string
  subject: string
  bodyText: string
  bodyHtml: string
  configurationSet: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: Record<string, any> // TODO: Improve this type
  created: number
  lastStatus: EmailStatus

  log?: MessageLog[]
}

export interface MessageLog {
  timestamp: string
  type: string
}

export enum EmailStatus {
  QUEUED = 'queued',
  REQUESTED = 'requested',
  FAILED = 'failed'
}
