import { BookingBreakdown, BookingPhotographerMap } from '.'
import { Contact, EventBase, EventBaseStartTime, Note, Venue } from '..'
import { Market } from '../common'
import { BookingChangeRequest } from './BookingChangeRequests'

export enum BookingStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export interface Booking extends EventBase, EventBaseStartTime {
  accountCode: string
  accountHolderContactInfo: Contact
  accountId: string
  accountName: string
  aimsBusinessNo: number
  aimsJobId: string
  areaCode: string
  arrivalDate: string
  arrivalTime: string
  arrivalUnixTimestamp: number
  arrivalTimestamp: string
  breakdown: BookingBreakdown[]
  bookingDepositPence?: number
  cancelReason?: string
  cancelNotes?: string
  commission: boolean
  contact: Contact
  confirmed: boolean
  deliveryContact?: Contact | Record<string, unknown> // RF - Added Record<string, unknown> as it appears Bookings are being created with deliveryContact set to an empty object!
  deliveryAddress?: Venue
  datalinkingContact?: Contact
  hasRigPhotographers: boolean
  hasSharedPhotographers: boolean
  headCount: number
  market: Market | null
  status: BookingStatus
  jobType: string
  notes: Note[]
  options?: { noNotificationOnCreated?: boolean }
  photographersMap?: BookingPhotographerMap
  setupTime: number
  venue: Venue
  labUploaders?: string[] // All people in the lab who have uploaded to this booking on behalf of a photographer
  hasJobs?: boolean
  hasChangeRequests?: boolean
  changeRequests?: BookingChangeRequest[]
}
