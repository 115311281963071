export enum State {
  Added = 'ADDED',
  Deleted = 'DELETED',
  Modified = 'MODIFIED'
}

export interface Log {
  object: string
  objectId: string
  objectType: string
  state: State
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  before: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  after: any
  timestamp: number
  userId: string
  userName: string
  userUpdatedAt: Date
}
