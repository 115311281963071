import { AddressGeometry, GeoCoordinates } from '..'
import { Identity } from '../organisation'
import Timestamp from '../timestamp'

export enum UserRoles {
  EXTERNAL_USER = 'external-user',
  TEMPEST_STAFF = 'tempest-staff',
  COMMISSION_ADMIN = 'Commission Admin',
  DATA_TEAM = 'Data Team',
  QC_REVIEWER = 'Quality Control Reviewer',
  SOFTWARE_GODS = 'Software Engineering',
  SALES_SUPPORT = 'Sales Support', // Member of the sales team - grants them access to special Sales Support features
  IMAGE_EDITING = 'Image Editing',
  IMAGE_EDITING_ADMIN = 'Image Editing Admin',
  PAYMENT_PROCESSING_ADMIN = 'Payment Processing Admin',
  PAYMENT_PROCESSING = 'Payment Processing',
  AREA_MANAGER = 'Area Manager'
}

export interface AccountBoundaries {
  id: string
  accountBoundaries: GeoCoordinates[]
}

export interface UserProfile {
  accessibleOrganisations?: Identity[]
  accountsLoaded?: boolean
  companyName?: string
  department?: string
  displayName: string
  disabled?: boolean
  email: string
  givenName?: string
  googleIAMid?: string
  homeAddressGeometry?: AddressGeometry
  homeAddressSet?: boolean
  isAccountHolder?: boolean
  isRigPhotographer?: boolean
  isSalesTeamMember?: boolean
  isSalesTeamAdmin?: boolean
  allowSalesAppAccess?: boolean
  allowMentorAccess?: boolean
  isSharedPhotographer?: boolean
  jobTitle?: string
  lastGoogleIAMSync?: number
  lastLoggedIn?: Timestamp
  lastUpdatedTimestamp?: Timestamp
  lastUpdatedUser?: string
  lastUpdatedUserId?: string
  organisationBoundaries?: AccountBoundaries[]
  mail?: string
  mobilePhone?: string
  notificationCount?: number
  numberOfAccounts?: number
  officeLocation?: string
  photoURL?: string
  photographerId?: string
  roles?: Array<UserRoles | string>
  unreadTeamNotifications?: number
}

export function userHasRoles (userRoles: UserProfile | UserRoles[], searchRoles: UserRoles[]): boolean {
  const roles = Array.isArray(userRoles) ? userRoles : (userRoles.roles ?? [])
  return roles.some(x => searchRoles.some(y => x === y))
}
