import { Market } from '../common'
import TimeStamp from '../timestamp'
import { UserRoles } from '../userProfile'
import { JobAccount } from './jobAccount'

export enum JobStatus {
  DRAFT = 'DRAFT',
  WAITING_FOR_PHOTOGRAPHERS = 'WAITING FOR PHOTOGRAPHERS',
  AWAITING_APPROVAL = 'AWAITING APPROVAL',
  UPLOAD_LOCKED = 'UPLOAD LOCKED',
  IN_REVIEW = 'IN REVIEW',
  AWAITING_COMMISSION = 'AWAITING COMMISSION',
  REQUIRES_AIMS_NO = 'REQUIRES AIMS NO',
  READY_FOR_QC = 'READY FOR QC',
  QC_IN_PROGRESS = 'QC IN PROGRESS',
  COMPLETE = 'COMPLETE'
}

export enum PhotographerJobStatus {
  AWAITING = 'AWAITING',
  UPLOADING = 'UPLOADING',
  COMPLETE = 'COMPLETE'
}

export enum PhotographerRole {
  ABSENT = 'absent',
  PHOTOGRAPHER = 'photographer',
  ASSISTANT = 'assistant'
}

export interface ImportJobToNexusMessage {
  jobId: string
}

export interface Photographer {
  name: string
  id: string
  email: string
  code: string
  commission: number | null
  imageCount: number
  role: PhotographerRole
  status: PhotographerJobStatus
}

export interface JobPhotographerMap { [id: string]: Photographer }

export enum NexusImportStatus {
  AWAITING_REIMPORT = 'Awaiting ReImport',
  COMPLETED = 'Completed',
  PROCESSING = 'Processing',
  FAILED = 'Failed'
}

export interface Job {
  /**
   * TODO: RF -- THIS TYPE IS WRONG! The type in Firestore is actually a firestore Timestamp, not a Date! 😠
   */
  readonly accountId: string
  readonly bookingId: string
  account: JobAccount
  accountCommissionMod: number | null
  accountCommissionWeb: number | null
  aimsJobNumber: string | null
  aimsNumAddedUnixTimestamp: number | null
  allowUploadOverride?: boolean
  availableOnline: boolean
  classGroupImages?: { [key: string]: string }
  committedToLabUnixTimestamp: number | null
  createUnixTimestamp: number
  hasAccountApproval: boolean
  hasAimsJobNumber: boolean
  hasBeenQCed: boolean
  hasCommissionValues: boolean
  imageCount: number
  importedCount: number
  importToNexusProgressId: string | null
  isCommittedToLab: boolean
  isDataLinked: boolean
  jobType: string
  labUploaders?: string[] // All people in the lab who have uploaded to this job on behalf of a photographer
  market: Market | null
  nexusImportDescription: string | null
  nexusImportStatus: NexusImportStatus | null
  nexusJobId: number | null
  notes: string
  onHold: boolean
  outOfHours: boolean
  photographersMap: JobPhotographerMap
  status: JobStatus
  takeDate: Date
  takeUnixTimestamp: number
  usedNameSearch: boolean

  onHoldColorId?: string | null

  userViewing?: {
    id: string
    name: string
  } | null

  isCancelled?: boolean
}

export type JobFromFirestore = Omit<Job, 'takeDate'> & { takeDate: TimeStamp | string }

export function jobStatusUserComplete (roles: Array<UserRoles | string>): JobStatus[] {
  const completeStatuses = [JobStatus.COMPLETE]

  if (roles.includes(UserRoles.DATA_TEAM)) {
    completeStatuses.push(JobStatus.READY_FOR_QC, JobStatus.QC_IN_PROGRESS)
  } else if (roles.includes(UserRoles.COMMISSION_ADMIN)) {
    completeStatuses.push(JobStatus.READY_FOR_QC, JobStatus.QC_IN_PROGRESS, JobStatus.REQUIRES_AIMS_NO)
  }

  return completeStatuses
}

export function jobStatusUserToAction (roles: Array<UserRoles | string>): JobStatus[] {
  const QC_JOBS = [JobStatus.READY_FOR_QC, JobStatus.QC_IN_PROGRESS]
  const DATA_ENTRY_JOBS = [JobStatus.REQUIRES_AIMS_NO]
  const COMMISSION_JOBS = [JobStatus.AWAITING_COMMISSION]

  let visibleJobs: JobStatus[] = []

  for (const role of roles) {
    switch (role) {
      case UserRoles.SOFTWARE_GODS:
        return [...QC_JOBS, ...COMMISSION_JOBS, ...DATA_ENTRY_JOBS]
      case UserRoles.QC_REVIEWER:
        visibleJobs = visibleJobs.concat(QC_JOBS)
        break
      case UserRoles.COMMISSION_ADMIN:
        visibleJobs = visibleJobs.concat(COMMISSION_JOBS)
        break
      case UserRoles.DATA_TEAM:
        visibleJobs = visibleJobs.concat(DATA_ENTRY_JOBS)
        break
    }
  }

  return visibleJobs
}
